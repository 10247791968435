<template>
  <div>
    <div id="pageContact" class="mainContents">
      <section class="titleArea">
        <h2>お問い合わせ</h2>
        <!-- /.titleArea -->
      </section>

      <form enctype="multipart/form-data">
        <div class="contactArea">
          <template v-if="!completed">
            <div class="inner">
              <p class="text">
                お問い合わせにつきましては、以下フォームをご利用いただくか、メール（contact@timecapsules.jp）までご連絡ください。
              </p>
              <ul>
                <li v-for="error in errors" :key="error.message">
                  {{ error.message }}
                </li>
              </ul>
              <dl>
                <div class="mail">
                  <dt>メールアドレス<span class="required">*</span></dt>
                  <dd class="inputWrap">
                    <input
                      v-model="contact.email"
                      type="text"
                      placeholder="time@mail.com"
                      :class="{ 'text-danger': !validEmail }"
                    >
                  </dd>
                </div>
                <div class="name">
                  <dt>お名前<span class="required">*</span></dt>
                  <dd class="inputWrap">
                    <input
                      v-model="contact.name"
                      type="text"
                      placeholder="山田太郎"
                      :class="{ 'text-danger': !validName }"
                    >
                  </dd>
                </div>
                <div>
                  <dt>件名<span class="required">*</span></dt>
                  <dd class="inputWrap">
                    <input
                      v-model="contact.subject"
                      type="text"
                      placeholder="件名"
                      :class="{ 'text-danger': !validSubject }"
                    >
                  </dd>
                </div>
                <div>
                  <dt>お問い合わせ内容<span class="required">*</span></dt>
                  <dd class="inputWrap">
                    <textarea
                      v-model="contact.message"
                      placeholder="問い合わせ内容"
                      :class="{ 'text-danger': !validMessage }"
                    />
                  </dd>
                </div>
              </dl>

              <div class="buttonArea">
                <p class="submit">
                  <button
                    type="submit"
                    :disabled="formInvalid"
                    @click="send($event)"
                  >
                    送信
                  </button>
                </p>
                <!-- /.buttonArea -->
              </div>
            </div>
          </template>
          <template v-if="completed">
            <div class="inner">
              <p class="text thanks_text">
                送信完了しました。
              </p>
              <div class="buttonArea">
                <p class="submit">
                  <router-link
                    :to="{ name: 'home' }"
                  >
                    TOPページに戻る
                  </router-link>
                </p>
                <!-- /.buttonArea -->
              </div>
            </div>
          </template>
          <!-- /.contactArea -->
        </div>
      </form>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import { fieldNotNull, validEmail } from '@/helpers/validator.js'

export default {
  components: {},
  data () {
    return {
      contact: {},
      isUploading: false,
      completed: false,
      errors: null
    }
  },
  computed: {
    validName: function () {
      return fieldNotNull(this.contact.name)
    },
    validSubject: function () {
      return fieldNotNull(this.contact.subject)
    },
    validEmail: function () {
      return fieldNotNull(this.contact.email) && validEmail(this.contact.email)
    },
    validMessage: function () {
      return fieldNotNull(this.contact.message)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validName) {
        return true
      }

      if (!this.validSubject) {
        return true
      }

      if (!this.validEmail) {
        return true
      }

      if (!this.validMessage) {
        return true
      }

      return false
    }
  },
  mounted () {},
  methods: {
    send (event) {
      event.preventDefault()
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('contact/sendContact', this.contact)
        .then(res => {
          this.isUploading = false
          NProgress.done()
          if (res.data.errors) {
            this.errors = res.data.errors
          } else {
            this.completed = true
          }
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
        })
    }
  }
}
</script>
